// noinspection ES6UnusedImports
import React from 'react';
import {createRoot} from 'react-dom/client';
import WishlistNavigationHeader from '../../components/WishlistNavigationHeader.jsx';
import JshModule from "@core/scripts/helper/jsh-module";

const {moduleScope, config} = JshModule('header/wishlist/wishlist-navigation-header');

createRoot(moduleScope).render(
    <WishlistNavigationHeader
        wishlistUrl={config.wishlistUrl}
        linkTitle={config.linkTitle}
        wishlistCount={parseInt(config.wishlistCount)}
        deviceType={config.deviceType}
    />
);


